<template>
  <div class="content">
    <v-row justify="center">
      <!-- ---------------LOADER PROCESANDO DATOS ---------------- -->
      <div v-if="loading">
        <loader
          object="#4caf50"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="40"
          disableScrolling="false"
          name="box"
        ></loader>
      </div>

      <!-- ---------------BOTON NUEVO USUARIO BLOQUEADO ---------------- -->
      <div class="col-md-12" align="right">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          class="mb-4"
          v-on="on"
          @click="showDialogBloq"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          Nuevo
        </v-btn>
      </div>
    </v-row>

    <!-- DATATABLE -->
    <div class="content">
      <div class="container-fluid">
        <v-app>
          <v-main>
            <div class="card">
              <!-- ----------titulo---------------- -->
              <div class="card-header card-header-success">
                <h4 class="card-title">Usuarios Bloqueados</h4>
              </div>
              <div class="card-body">
                <!-- -------------------- SEARCH  ------------------>
                <v-row class="pt-0 mx-5">
                  <!-- Fecha inicio -->
                  <v-col cols="6" md="4">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ml-5"
                          label="Fecha desde"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                          :value="FormatDateDesde"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date1"
                        locale="es-ar"
                        no-title
                        scrollable
                        :max="new Date().toISOString().substr(0, 10)"
                        @change="inicio(date1 + 'T00:00:00-03:00')"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Fecha Fin -->
                  <v-col cols="6" md="4">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ml-2"
                          :value="FormatDateHasta"
                          label="Fecha hasta"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        locale="es-ar"
                        no-title
                        scrollable
                        :min="fechaDesde"
                        :max="new Date().toISOString().substr(0, 10)"
                        @change="fin(date2 + 'T23:59:59-03:00')"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Select para cuentas -->
                  <v-col>
                    <v-select
                      :items="cuentasConTodos"
                      label="Cuenta"
                      item-value="id"
                      v-model="cuenta_id"
                      @change="getDataNumber"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row class="mb-2 justify-end">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- -------------------- DATATABLE  ------------------>
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="registros"
                  :loading="loading"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  class="elevation-0 trow"
                  disable-pagination
                  hide-default-footer
                  loading-text="Cargando... Por favor espere"
                  no-data-text="No se encontraron registros"
                  no-results-text="No se encontraron registros que coincidan"
                  disable-sort
                >
                  <template v-slot:[`item.cuenta`]="{ item }">
                    {{ item.cuenta ? item.cuenta : "-" }}
                  </template>
                  <template v-slot:[`item.nombre`]="{ item }">
                    {{ item.nombre ? item.nombre : "-" }}
                  </template>
                  <!-- ------- Fecha de bloqueo ----- -->
                  <template v-slot:[`item.fecha_bloqueo`]="{ item }">
                    {{
                      item.fecha_bloqueo === "0001-01-01T00:00:00Z"
                        ? "-"
                        : formatDate(item.fecha_bloqueo)
                    }}
                  </template>
                  <!-- ----------- Permananente ---------- -->
                  <template v-slot:[`item.permanente`]="{ item }">
                    <v-chip
                      color="success"
                      dark
                      v-if="item.permanente"
                      small
                      class="white--text"
                      >Si
                    </v-chip>
                    <v-chip color="error" dark v-else small class="white--text">
                      No
                    </v-chip>
                  </template>
                  <!-- ---------- ACCIONES ------------ -->
                  <!-- 1. Editar -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top color="grey darken-1">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mr-2"
                          fab
                          dark
                          x-small
                          color="orange"
                          v-on="on"
                          @click="showFormEditBloq(item)"
                        >
                          <v-icon dark> create </v-icon>
                        </v-btn>
                      </template>
                      <span>Editar Usuario</span>
                    </v-tooltip>
                    <!-- 2. Eliminar -->
                    <v-tooltip top color="grey darken-1">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          fab
                          dark
                          x-small
                          color="red"
                          v-on="on"
                          @click="eliminarBloq(item)"
                        >
                          <v-icon dark> delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar Usuario</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    @input="getData"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </v-main>
        </v-app>
      </div>
    </div>
    <!-- FORM USUARIO BLOQUEADO (NUEVO, EDITAR y ELIMINAR) -->
    <DialogBloqueados ref="dialogBloq" @refreshUsuariosBloq="getData" />
  </div>
</template>

<script>
import { userBloqService } from "@/services/administrar";
import { clientesService } from "@/services/administrar";
import formatDate from "@/utils/formatDate";
import DialogBloqueados from "./DialogBloqueados.vue";
export default {
  name: "UsuariosBloqueados",
  components: {
    DialogBloqueados,
  },
  data: () => ({
    loading: false,
    //data para select de Cuentas en el search
    cuentas: [],
    cuenta: "",
    cuenta_id: "",
    search: "",

    headers: [
      //{ text: "Cliente", value: "id" },
      { text: "Cuenta", value: "cuenta" },
      { text: "Nombre", value: "nombre" },
      { text: "Email", value: "email" },
      { text: "DNI", value: "dni" },
      { text: "CUIT", value: "cuit" },
      { text: "Fecha de bloqueo", value: "fecha_bloqueo" },
      { text: "Cantidad de bloqueos", value: "cant_bloqueo" },
      { text: "Permanente", value: "permanente" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    registros: [],

    //PAGINACION
    page: 1, // pagina actual de la tabla
    pageCount: 0, // cantidad de paginas de la tabla
    itemsPerPage: 10, // cantidad de registros por pagina

    formatDate,

    //Setteo las fechas por defecto a un mes antes como inicio y la fecha de hoy como fin
    fechaDesde: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DDTHH:mm:ssZ"),
    fechaHasta: moment().format("YYYY-MM-DDTHH:mm:ssZ"),

    // datapicker filtro fechas
    menu1: false, // atributo que permite desplegar el date picker fecha desde
    menu2: false, // atributo que permite desplegar el date picker fecha hasta
    // campos para hacer referencia a valor de datapicker
    date1: "",
    date2: "",
  }),

  computed: {
    // Clonamos el arreglo original para no modificarlo directamente y agrego un campo mas llamado "TODOS" para que me traiga todos los datos
    cuentasConTodos() {
      const cuentasClone = [...this.cuentas];
      // Agregamos la opción "Todos" al inicio del arreglo
      cuentasClone.unshift({ id: "", text: "TODOS" });
      return cuentasClone;
    },

    // Para dar formato a los filtros de fechas
    FormatDateDesde() {
      return this.fechaDesde
        ? moment(this.fechaDesde).format("DD/MM/YYYY")
        : "";
    },
    FormatDateHasta() {
      return this.fechaHasta
        ? moment(this.fechaHasta).format("DD/MM/YYYY")
        : "";
    },
  },

  //ESTE ESTA
  created() {
    this.date1 = moment()
      .subtract(1, "months")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    this.date2 = moment().format("YYYY-MM-DDTHH:mm:ssZ");
  },

  mounted() {
    this.getData();
    this.getClientes();
  },

  methods: {
    showDialogBloq() {
      this.$refs.dialogBloq.$emit("showDialogBloqNuevo");
    },
    showFormEditBloq(item) {
      this.$refs.dialogBloq.$emit("showFormEditBloq", item);
    },

    // Funcion de Datapicker cuando selecciono fecha inicio
    inicio(value) {
      this.date1 = value;
      this.fechaDesde = value;
      this.menu1 = false;
    },

    // Funcion de datapicker cuando selecciono la fecha fin
    fin(value) {
      this.page = 1;
      this.date2 = value;
      this.fechaHasta = value;
      this.menu2 = false;
      this.getData();
    },

    // Para que el select siempre primero traiga desde la primera pagina "Number"
    async getDataNumber() {
      this.page = 1;
      this.getData();
    },

    // Traer Todos los Datos
    async getData() {
      try {
        const params = {
          Number: this.page,
          Size: 10,
          FechaInicio: this.date1,
          FechaFin: this.date2,
          Cuenta: this.cuenta_id,
        };

        this.loading = true;
        const data = await userBloqService.getAll(params);

        this.registros = data?.data?.usuarios || [];
        this.pageCount = data?.data?.meta.page.lastPage;

        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
      }
    },
    async eliminarBloq(item) {
      const payload = {
        Id: item.id,
      };
      /*-------------- Alerta de confirmacion -------------- */
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        text: "Esta acción eliminará el usuario bloqueado.",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        confirmButtonColor: "#50b565",
        denyButtonText: "Cancelar",
      });
      if (isConfirmed) {
        try {
          this.loading = true;
          await userBloqService.deleteBloqUser(payload);

          this.loading = false;
          this.getData();

          this.$toastr.s("Usuario bloqueado eliminado con exito");
        } catch (error) {
          this.loading = false;
          // console.error(error);

          this.$toastr.e("Error al eliminar el usuario bloqueado");
        }
      }
    },

    //Para traer todos los clientes y mostrar las cuentas para el Select
    async getClientes() {
      const params = {
        CargarImpuestos: false,
        CargarCuentas: true,
        CargarRubros: false,
        // CargarCuentasSubcuenta: true,
      };
      //   //Traigo los datos de los clientes en data
      const { data } = await clientesService.obtenerClientes(params);
      //   //Tengo almacenado los datos de mis clientes en el arreglo this.clientes
      this.clientes = data;

      //   // Funcion para el select de Cuenta, hago un mapeo para traer todas las cuentas y lo guardo en mi arreglo "cuentas[]"
      this.cuentas = this.clientes
        .map((cliente) => {
          // Mapeamos todas las cuentas del cliente y devolvemos un arreglo de objetos con la información de cada cuenta
          return cliente.cuenta.map((cuenta) => {
            return {
              id: cuenta.id, // Utilizamos el id de la cuenta
              text: `${cuenta.cuenta}`, // Creamos el texto con el id del cliente y el nombre de la cuenta
            };
          });
        })
        .flat(); // Uso flat porque dentro de mi arreglo cuentas hay arreglos que serian de los clientes con mas cuentas.Lo que hace es ponerlos todos al mismo nivel.
    },
  },
};
</script>

<style scoped>
a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}

.theme--light.v-subheader {
  color: rgb(27 15 216 / 89%);
}
</style>
